import React from 'react';
import {Link} from 'gatsby';

const PageNotFound = () => (
	<div>
		Page not found. <Link to="/">Home</Link>
	</div>
);

export default PageNotFound;
